<div class="buttonStyle row">
    <button class="btn btn-primary abc col-sm-2" (click)="switchtab('MARKER')">Marker</button>
    <button class="btn btn-primary abc col-sm-2" (click)="switchtab('CANDIDATE')">Candidates</button>
    <!-- <button class="btn btn-primary col-sm-3" (click)="switchtab('idUpload')">Generate Random IDs</button> -->
</div>

<div *ngIf="selectedTab=='marker'" class="col-sm-12 pull-left" style="padding: 0px" >
    <div class="row">
        <div class="col-md-12 col-xs-12 box"
            style="padding: 0px 34px 6px 34px;">
            <div class="task_details">
                <div>

                    <div class="table-responsive " >
                        <table class="table">
                            <tr>
                              
                                <th>Marker Name</th>
                                <th>Phone number </th>

                                <th>Email</th>

                                <th>Attendance</th>
                               
                               
                            </tr>

                            <tr>
                             




                                <!-- <td *ngIf="col.room==null">NA</td>
                                <td *ngIf="col.room!=null">{{ col.room.roomName }}</td>

                                <td *ngIf="col.floor!=null">{{ col.floor.floorName }}</td>
                                <td *ngIf="col.floor==null">NA</td>


                                <td *ngIf="col.building==null">NA</td>
                                <td *ngIf="col.building!=null">{{ col.building.buildingName }}</td>

                                <td *ngIf="col.site==null">NA</td>
                                <td *ngIf="col.site!=null">{{ col.site.siteName }}</td>

                                <td *ngIf="col.startTime==null">NA</td>
                                <td *ngIf="col.startTime!=null">{{ col.startTime }}</td>

                                <td *ngIf="col.endTime==null">NA</td>
                                <td *ngIf="col.endTime!=null">{{ col.endTime }}</td>

                                <td *ngIf="col.classMode==null">NA</td>
                                <td *ngIf="col.classMode!=null">{{ col.classMode }}</td>

                                <td *ngIf="col.classSubject==null">NA</td>
                                <td *ngIf="col.classSubject!=null">{{ col.classSubject }}</td>

                                <td *ngIf="col.classTopic==null">NA</td>
                                <td *ngIf="col.classTopic!=null">{{ col.classTopic }}</td>

                                <td *ngIf="col.classStatus==null">NA</td>
                                <td *ngIf="col.classStatus!=null">{{ col.classStatus }}</td>

                                <td *ngIf="col.attendanceStatus==null">NA</td>
                                <td *ngIf="col.attendanceStatus!=null">{{ col.attendanceStatus }}</td>


                                <td *ngIf="col.openUrlLink==null">NA</td>
                                <td *ngIf="col.openUrlLink!=null">{{ col.openUrlLink }}</td>


                                <td class="text-right">
                                    <div class="dropdown dropdown-action">
                                        <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" data-toggle="modal" data-target="#markerList" (click)="listMarkerCandi(col.timeTableId)" ><i class="fa fa-pencil m-r-5"></i> List</a>

                                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                                        </div>
                                    </div>
                                </td>
                            -->

                            </tr>
                        </table>

                    </div>
                   

                 

                </div>
            </div>
           
     


        </div>
    </div>


</div>