import { Component, OnInit } from '@angular/core';
import jwt_decode from "jwt-decode";
import { MarkerServiceService } from 'src/app/service/marker-service/marker-service.service';
@Component({
  selector: 'app-list-marker-candidates',
  templateUrl: './list-marker-candidates.component.html',
  styleUrls: ['./list-marker-candidates.component.css']
})
export class ListMarkerCandidatesComponent implements OnInit {
  token:any;
  decode:any;
  
  constructor(private markerList:MarkerServiceService) {
    this.token= localStorage.getItem("access_token");
    var decoded = jwt_decode(this.token);
     this.decode = JSON.parse(JSON.stringify(decoded));
   }

  ngOnInit(): void {
  }
  selectedTab:any;
  switchtab(tabType:any)
  {
    this.selectedTab = tabType
    this.listMarkerData(0,10,"","");

  }
  currentOrder:any
  globalSearchStr:any;
  listMarkerData(pageNumber: any, numberOfRecords: any, search: any, order: any) {
    debugger
    if (order == null || order == '' || order == undefined) {
      if (this.currentOrder == null || this.currentOrder == '') {
        order = 'startTime_ASC';
      }
      else {
        order = this.currentOrder;
      }
    }
    if (numberOfRecords == null || numberOfRecords == '' || numberOfRecords == undefined) {
      numberOfRecords = '10';
    }
    if (search != null && search != "" && search != undefined) {
      this.globalSearchStr = search;
    }
    else {
      this.globalSearchStr = null;
    }
  
    var body = {
      //  organizationId:this.decode.institute_id,
      //  userType:this.selectedTab,
  
  
    }
  
    this.markerList.markerList(pageNumber,numberOfRecords,order,body).subscribe(
      (data: any) => {
        var l_output  = data["output"];
        if (l_output.statusCode == "200") {
          debugger;
        }
      },
      (err) => console.log(err)
    );
  }

}
