import { Component, OnInit,Input,OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-view-request-json',
  templateUrl: './view-request-json.component.html',
  styleUrls: ['./view-request-json.component.css']
})
export class ViewRequestJsonComponent implements OnInit,OnChanges {
@Input() requestJsonData:any;
  constructor() { }

  ngOnInit(): void {
  }
requestData:any;
  ngOnChanges(changes: SimpleChanges): void {
    this.requestData= this.requestJsonData 
  }

}
