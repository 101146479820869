import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewResponseJsonRoutingModule } from './view-response-json-routing.module';
import { ViewResponseJsonComponent } from './view-response-json.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    ViewResponseJsonComponent
  ],
  imports: [
    CommonModule,
    ViewResponseJsonRoutingModule,FormsModule,ReactiveFormsModule
  ],
  exports:[
    ViewResponseJsonComponent
  ]
})
export class ViewResponseJsonModule { }
