<div class="overlay"></div>
    <div class="popup-content">
        <div class="modal-header">
            <h4 class="modal-title fontSize">Image</h4>
           
           

            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" (click)="closeImagePopup()">&times;</span>
            </button>
        </div>
     
        <div class="col-sm-5 col-xs-5 inTimeIMagePopup">

            <h4 class="modal-title fontSize">{{candidateProfileImage}}</h4>

            </div>
        
      
          
        <div class="col-sm-12 col-xs-12">
        <img class="showImage" src="{{imageCandidate}}" alt="Image">
        </div> 
    </div>
