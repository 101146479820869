import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddCandidatesTimetableRoutingModule } from './add-candidates-timetable-routing.module';
import { AddCandidatesTimetableComponent } from './add-candidates-timetable.component';
import { HeaderModule } from 'src/app/header/header.module';
import { SidebarModule } from 'src/app/sidebar/sidebar.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddTimeTableModule } from 'src/app/time-table/add-time-table/add-time-table.module';
import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';
import { ViewRecurrenceModule } from 'src/app/recurrence-task/view-recurrence/view-recurrence.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AddCandidatesTimetableComponent
  ],
  imports: [
    CommonModule,
    AddCandidatesTimetableRoutingModule,HeaderModule,SidebarModule,FormsModule,ReactiveFormsModule,
    NgMultiSelectDropDownModule,AddTimeTableModule,NgxLoaderModule,ViewRecurrenceModule
  ],
  exports: [
    AddCandidatesTimetableComponent
  ]
})
export class AddCandidatesTimetableModule { }
