import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewRecurrenceRoutingModule } from './view-recurrence-routing.module';
import { ViewRecurrenceComponent } from './view-recurrence.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    ViewRecurrenceComponent
  ],
  imports: [
    CommonModule,
    ViewRecurrenceRoutingModule,FormsModule,ReactiveFormsModule
  ],
  exports: [
    ViewRecurrenceComponent
  ]
})
export class ViewRecurrenceModule { }
