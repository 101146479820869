import { Component, OnInit,Input,OnChanges,SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-view-response-json',
  templateUrl: './view-response-json.component.html',
  styleUrls: ['./view-response-json.component.css']
})
export class ViewResponseJsonComponent implements OnInit,OnChanges {

  @Input() responseJsonData:any;
  constructor() { }

  ngOnInit(): void {
  }
  responseData:any;
  ngOnChanges(changes: SimpleChanges): void {
    this.responseData= this.responseJsonData 
  }
}
