import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCandidatesTimetableComponent } from './add-candidates-timetable.component';

const routes: Routes = [{ path: '', component: AddCandidatesTimetableComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddCandidatesTimetableRoutingModule { }
