import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ViewResponseJsonComponent } from './view-response-json.component';

const routes: Routes = [{ path: '', component: ViewResponseJsonComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViewResponseJsonRoutingModule { }
