import { Component, OnInit,Input,OnChanges } from '@angular/core';

@Component({
  selector: 'app-add-popup',
  templateUrl: './add-popup.component.html',
  styleUrls: ['./add-popup.component.css']
})
export class AddPopupComponent implements OnInit,OnChanges {
@Input() imageCandidate:any;
@Input() candidateProfileImage:any;
  constructor() { }
  showImagePopup = false;
  ngOnInit(): void {
  }
  closeImagePopup() {
    this.showImagePopup = false;
  }

  ngOnChanges(): void {
    this.imageCandidate=this.imageCandidate;
    this.candidateProfileImage=this.candidateProfileImage;
  }

}
