import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BulkUploadService {

  private API_URL =environment.baseUrl
  constructor(private httpClient: HttpClient) { }

  public downloadEmptySampleFileGroup(json:any){
    debugger
    return this.httpClient.post(this.API_URL + "/csvfile/downloadCSV_group_empty" , json)
  }

  downloadSampleFile(json:any)
  {
    return this.httpClient.post(this.API_URL + "/csvfile/downloadCSV_group" , json)
  }


  saveGroupDataInBulk(body)
  {
    return this.httpClient.post(this.API_URL + "/facedocumentapi/bulkuploaddocumentGroup" , body)
  }

  saveCandidatesDataInBulk(body)
  {
    return this.httpClient.post(this.API_URL + "/facedocumentapi/bulkuploaddocumentCandidate" , body)
  }

  saveMarkerDataInBulk(body)
  {
    return this.httpClient.post(this.API_URL + "/facedocumentapi/bulkuploaddocumentMarker" , body)
  }

  sendEmailInBulk(body)
  {
    return this.httpClient.post(this.API_URL + "/facedocumentapi/sendMailInBulk" , body)
  }

  saveTimetableDataInBulk(body)
  {
    return this.httpClient.post(this.API_URL + "/facedocumentapi/bulkuploaddocumentTimetable" , body)
  }

  getFilUploadGroup(fileUploadId)
  {
    return this.httpClient.get(this.API_URL + "/fileuploadapi/fileUpload/"+fileUploadId)
  }


  getFilUploadProgressGroup(fileUploadProgressId)
  {
    return this.httpClient.get(this.API_URL + "/fileuploadprogressapi/fileUploadProgressbYfileUpload/"+fileUploadProgressId)
  }
}
