import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddPopupRoutingModule } from './add-popup-routing.module';
import { AddPopupComponent } from './add-popup.component';


@NgModule({
  declarations: [
    AddPopupComponent
  ],
  imports: [
    CommonModule,
    AddPopupRoutingModule
  ]
})
export class AddPopupModule { }
