import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListMarkerCandidatesRoutingModule } from './list-marker-candidates-routing.module';
import { ListMarkerCandidatesComponent } from './list-marker-candidates.component';


@NgModule({
  declarations: [
    ListMarkerCandidatesComponent
  ],
  imports: [
    CommonModule,
    ListMarkerCandidatesRoutingModule
  ],
  exports: [ListMarkerCandidatesComponent]
})
export class ListMarkerCandidatesModule { }
