import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewRequestJsonRoutingModule } from './view-request-json-routing.module';
import { ViewRequestJsonComponent } from './view-request-json.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ViewRequestJsonComponent
  ],
  imports: [
    CommonModule,
    ViewRequestJsonRoutingModule,FormsModule,ReactiveFormsModule
  ],
  exports:[
    ViewRequestJsonComponent
  ]
})
export class ViewRequestJsonModule { }
